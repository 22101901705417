require('./bootstrap');

/* Vue Code */
import { createApp } from 'vue'
import NotesWidget from './components/NotesWidget'
import TimeclockAdminWidget from './components/TimeclockAdminWidget'
import ShopifyOrderFulfillment from './components/ShopifyOrderFulfillment'
// import NotesWidget from './components/NotesWidget'

const app = createApp({})
app.component('notes-widget', NotesWidget)
app.component('timeclock-admin-widget', TimeclockAdminWidget)
app.component('shopify-order-fulfillment', ShopifyOrderFulfillment)
app.mount('#app')

require('jquery-validation')
require('datatables.net-bs4')

$(function() {
    var status_type = (typeof getUrlParameter('type') != "undefined") ? getUrlParameter('type') : '';

    // console.log(status1);
    $('#dataTable').DataTable({
        order: [],
        "lengthMenu": [ 10, 20, 50 ]
    });

    $(".table_link").on("click", function(){
        $('#sellsTable').DataTable().state.clear();
    });

    $('#website-sell-table').DataTable({
        processing: true,
        serverSide: true,
        bStateSave: true,
        ajax: {
            url: '/admin/websiteSellData/'+status_type,
        },
        columns: [
            { data: 'id', name: 'id' },
            { data: 'status', name: 'status' },
            { data: 'first_name', name: 'first_name' },
            { data: 'last_name', name: 'last_name' },
            { data: 'email', name: 'email' },
            { data: 'phone', name: 'phone' },
            { data: 'created_at', name: 'created_at' },
            { data: 'action', name: 'action', orderable: false, searchable: false }
        ],
        "columnDefs": [],
        order: [[0, 'desc']],
        "lengthMenu": [ 10, 20, 50 ]
    });

    $('#sellsTable').DataTable({
        processing: true,
        serverSide: true,
        bStateSave: true,
        ajax: {
            url: '/admin/sellsData/'+status_type,
        },
        columns: [
            { data: 'id', name: 'id' },
            { data: 'first_name', name: 'first_name' },
            { data: 'last_name', name: 'last_name' },
            { data: 'email', name: 'email' },
            { data: 'phone', name: 'phone' },
            { data: 'quantity', name: 'quantity' },
            { data: 'brands', name: 'brands' },
            { data: 'status', name: 'status' },
            { data: 'created_at', name: 'created_at' },
            { data: 'action', name: 'action', orderable: false, searchable: false }
        ],
        "columnDefs": [],
        order: [[0, 'desc']],
        "lengthMenu": [ 10, 20, 50 ]
    });

    $('#palletTrackerTable').DataTable({
        processing: true,
        serverSide: true,
        bStateSave: true,
        ajax: {
            url: '/admin/datatables/pallet_tracker_data',
        },
        columns: [
            { data: 'pallet_number', name: 'pallet_number' },
            { data: 'location', name: 'location_id' },
            { data: 'bags', name: 'bags' },
            { data: 'balls', name: 'balls' },
            { data: 'action', name: 'action', orderable: false, searchable: false }
        ],
        "columnDefs": [
            { targets: [2, 3], orderable: false }
        ],
        order: [[0, 'desc']],
        "lengthMenu": [ 10, 20, 50 ]
    });


    $('#sellRequestsTable').DataTable({
        processing: true,
        serverSide: true,
        bStateSave: true,
        ajax: {
            url: '/admin/sellRequestsData/'+status_type,
        },
        columns: [
            { data: 'id', name: 'id' },
            { data: 'status', name: 'status' },
            { data: 'ball_source', name: 'ball_source' },
            { data: 'quantity', name: 'quantity' },
            { data: 'first_name', name: 'customers.first_name' },
            { data: 'last_name', name: 'customers.last_name' },
            { data: 'created_at', name: 'created_at' },
            { data: 'action', name: 'action', orderable: false, searchable: false }
        ],
        "columnDefs": [],
        order: [[0, 'desc']],
        "lengthMenu": [ 10, 20, 50 ]
    });

    $('#checksTable').DataTable({
        processing: true,
        serverSide: true,
        bStateSave: true,
        ajax: {
            url: '/admin/checksData/'+status_type,
        },
        columns: [
            { data: 'id', name: 'id' },
            { data: 'name', name: 'banks.name' },
            { data: 'amount', name: 'amount' },
            { data: 'payee', name: 'payee' },
            { data: 'printed', name: 'printed' },
            { data: 'cleared', name: 'cleared' },
            { data: 'returned', name: 'returned' },
            { data: 'voided', name: 'voided' },
            { data: 'action', name: 'action', orderable: false, searchable: false }
        ],
        "columnDefs": [],
        order: [[0, 'desc']],
        "lengthMenu": [ 10, 20, 50 ]
    });

    $(".table_link").on("click", function(){
        $('#customersTable').DataTable().state.clear();
    });

    $('#customersTable').DataTable({
        processing: true,
        serverSide: true,
        bStateSave: true,
        ajax: {
            url: '/admin/customersData/'+status_type,
        },
        columns: [
            { data: 'id', name: 'id' },
            { data: 'first_name', name: 'first_name' },
            { data: 'last_name', name: 'last_name' },
            { data: 'email', name: 'email' },
            { data: 'phone', name: 'phone' },
            { data: 'city', name: 'city' },
            { data: 'state', name: 'state' },
            { data: 'zip', name: 'zip' },
            { data: 'created_at', name: 'created_at' },
            { data: 'action', name: 'action', orderable: false, searchable: false }
        ],
        "columnDefs": [],
        order: [[0, 'desc']],
        "lengthMenu": [ 10, 20, 50 ]
    });

    $('#queriesTable').DataTable({
        processing: true,
        serverSide: true,
        bStateSave: true,
        ajax: {
            url: '/admin/queriesData',
        },
        columns: [
            { data: 'id', name: 'id' },
            { data: 'instrument', name: 'instrument' },
            { data: 'brand', name: 'brand' },
            { data: 'model', name: 'model' },
            { data: 'first_name', name: 'first_name' },
            { data: 'last_name', name: 'last_name' },
            { data: 'email', name: 'email' },
            { data: 'phone', name: 'phone' },
            { data: 'created_at', name: 'created_at' },
            { data: 'action', name: 'action', orderable: false, searchable: false }
        ],
        "columnDefs": [],
        order: [[0, 'desc']],
        "lengthMenu": [ 10, 20, 50 ]
    });

    $('#donationsTable').DataTable({
        processing: true,
        serverSide: true,
        bStateSave: true,
        ajax: '/admin/donationsData',
        columns: [
            { data: 'id', name: 'id' },
            { data: 'status', name: 'status' },
            { data: 'phone', name: 'name' },
            { data: 'name', name: 'phone' },
            { data: 'email', name: 'email' },
            { data: 'zip', name: 'zip' },
            { data: 'quantity', name: 'quantity' },
            { data: 'quality', name: 'quality' },
            { data: 'created_at', name: 'created_at' },
            // { data: 'action', name: 'action', orderable: false, searchable: false }
        ],
        order: [[0, 'desc']],
        "lengthMenu": [ 10, 20, 50 ]
    });

    $('#contactsTable').DataTable({
        processing: true,
        serverSide: true,
        bStateSave: true,
        ajax: {
            url: '/admin/contactsData',
        },
        columns: [
            { data: 'id', name: 'id' },
            { data: 'name', name: 'name' },
            { data: 'email', name: 'email' },
            { data: 'message', name: 'message' },
            { data: 'created_at', name: 'created_at' }
        ],
        "columnDefs": [],
        order: [[0, 'desc']],
        "lengthMenu": [ 10, 20, 50 ]
    });

    

    $('#smsThreadsTable').DataTable({
        processing: true,
        serverSide: true,
        bStateSave: true,
        ajax: {
            url: '/admin/smsThreadsData/'+status_type,
        },
        columns: [
            { data: 'id', name: 'id' },
            { data: 'customer_phone', name: 'customer_phone' },
            { data: 'our_phone', name: 'our_phone' },
            { data: 'status', name: 'status' },
            { data: 'updated_at', name: 'updated_at' },
            { data: 'action', name: 'action', orderable: false, searchable: false }
        ],
        "columnDefs": [],
        order: [[4, 'desc']],
        "lengthMenu": [ 10, 20, 50 ]
    });
    
    $('#shopifyOrderFulfillmentTable').DataTable({
        processing: true,
        serverSide: true,
        bStateSave: true,
        ajax: {
            url: '/admin/shopifyOrderFulfillmentData/'+status_type,
        },
        columns: [
            { data: 'id', name: 'id' },
            { data: 'shopify_order_id', name: 'shopify_order_id' },
            { data: 'seq_num', name: 'seq_num' },
            { data: 'status', name: 'status' },
            { data: 'created_at', name: 'created_at' },
            { data: 'action', name: 'action', orderable: false, searchable: false }
        ],
        "columnDefs": [],
        order: [[1, 'desc']],
        "lengthMenu": [ 10, 20, 50 ]
    });

    $(document).on('click','.image-popup', function() {
        $('.image-preview').attr('src', $(this).attr('src'));
        $('#imagemodal').modal('show');
    }); 

});

$.date = function(dateObject) {
    var d = new Date(dateObject);
    var day = d.getDate();
    var month = d.getMonth() + 1;
    var year = d.getFullYear();
    var hour = d.getHours();
    var min = d.getMinutes();
    var amPm = "AM";

    if (hour>=12){
        hour = parseInt(hour) - 12;
        amPm = "PM";
    }
    return month+ "/" + day + "/" + year +' '+hour+':'+min+' '+amPm;
};

function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
};

function formatDate(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return (date.getMonth()+1) + "/" + date.getDate() + "/" + date.getFullYear() + "  " + strTime;
}

window.prepare_google_assist = function(){
    $(function () {
        $('#googleacInput').on('keyup keypress', function (e) {
            var keyCode = e.keyCode || e.which;
            if (keyCode === 13) {
                // e.preventDefault();
                return false;
            }
        });
    });

    window.autocomplete = null;
    window.address1Field = null;
    window.postalField = null;

    window.initAutocomplete = function() {
        address1Field = document.querySelector("#googleacInput");
        postalField = document.querySelector("#zipInput");
        // Create the autocomplete object, restricting the search predictions to
        // addresses in the US and Canada.
        autocomplete = new google.maps.places.Autocomplete(address1Field, {
            componentRestrictions: {
                country: ["us", "ca"]
            },
            fields: ["address_components", "geometry"],
            types: ["address"],
        });
        // address1Field.focus();
        // When the user selects an address from the drop-down, populate the
        // address fields in the form.
        autocomplete.addListener("place_changed", fillInAddress);
    }

    window.fillInAddress = function() {
        // Get the place details from the autocomplete object.
        const place = autocomplete.getPlace();
        let address1 = "";
        let postcode = "";
        let foundCity = false;

        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.
        // place.address_components are google.maps.GeocoderAddressComponent objects
        // which are documented at http://goo.gle/3l5i5Mr
        for (const component of place.address_components) {
            const componentType = component.types[0];

            switch (componentType) {
                case "street_number": {
                    address1 = `${component.long_name} ${address1}`;
                    break;
                }

                case "route": {
                    address1 += component.short_name;
                    break;
                }

                case "postal_code": {
                    postcode = `${component.long_name}${postcode}`;
                    break;
                }

                case "postal_code_suffix": {
                    postcode = `${postcode}-${component.long_name}`;
                    break;
                }
                // case "neighborhood":
                //     document.querySelector("#cityInput").value = component.long_name;
                //     foundCity = true;
                //     break;
                case "locality":
                    if (!foundCity) {
                        document.querySelector("#cityInput").value = component.long_name;
                    }
                    break;
                case "administrative_area_level_1": {
                    document.querySelector("#stateInput").value = component.short_name;
                    break;
                }
                case "country":
                    // document.querySelector("#country").value = component.long_name;
                    break;
            }
        }
        document.querySelector("#address_1Input").value = address1;
        // address1Field.value = address1;
        postalField.value = postcode;
        // After filling the form with address components from the Autocomplete
        // prediction, set cursor focus on the second address line to encourage
        // entry of subpremise information such as apartment, unit, or floor number.
        document.querySelector("#address_2Input").focus();
    }
}