<template>
    <div id="timeclock-admin">
        <div id='week'>
            <h3>
                Week of {{ startDate }}
            </h3>
            <button class="btn btn-primary" @click='previous_week'>Previous</button>
            <button class="btn btn-primary ml-5" @click='next_week' v-if="weeksBack > 0">Next</button>
        </div>
        <h3 class="mt-5">Week Total</h3>
        <table class="table table-dark table-striped">
            <thead class="thead-dark">
                <tr>
                    <th>Name</th>
                    <th>Worked</th>
                    <th>Out</th>
                    <th>Paid Breaks</th>
                    <th>Week Total</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="user in sortedPunches">
                    <td>{{user.user.name}}</td>
                    <td>{{user.worked}}</td>
                    <td>{{user.out}}</td>
                    <td>{{user.paidBreaks}}</td>
                    <td>{{user.weekTotal}}</td>
                </tr>
            </tbody>
        </table>
        <div v-for='user in sortedPunches'>
            <h3>{{user.user.name}} ({{user.weekTotal}})</h3>

            <table class="table table-dark table-striped">
                <thead class="thead-dark">
                    <tr>
                        <th>In</th>
                        <th>Out</th>
                        <th>Notes</th>
                        <th>Total</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for='punch in user.punches'>
                        <tr>
                            <td><input type="datetime-local" :punch_id="punch.id" punch_type="in_time"
                                    :value="convertTime(punch.in_time)" @blur="updateTime" /></td>
                            <td><input type="datetime-local" :punch_id="punch.id" punch_type="out_time"
                                    :value="convertTime(punch.out_time)" @blur="updateTime" /></td>
                            <td>{{punch.note}}</td>
                            <td>{{punch.total}}</td>
                            <td><i class="fa fa-times delete-icon" aria-hidden="true"
                                    @click="deletePunch(punch.id)"></i>
                            </td>
                        </tr>
                    </template>
                    <tr>
                        <td>
                            <input class='mr-5' type="datetime-local" :id="'add_in_' + user.user.id"
                                :min="convertTime(start)" :max="convertTime(end)" />
                        </td>
                        <td>
                            <input class='mr-5' type="datetime-local" :id="'add_out_' + user.user.id"
                                :min="convertTime(start)" :max="convertTime(end)" />
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                            <button @click="addPunch(user.user.id)">Add Punch</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <h3>Add Punch</h3>
        <table class="table table-dark table-striped">
            <thead class="thead-dark">
                <tr>
                    <th>User</th>
                    <th>In</th>
                    <th>Out</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>

                <tr>
                    <td>
                        <select id="add_user_id">
                            <option v-for="user in allUsers" :value="user.id">{{user.name}}</option>
                        </select>
                    </td>
                    <td>
                        <input class='mr-5' type="datetime-local" :id="'add_in_new'" :min="convertTime(start)"
                            :max="convertTime(end)" />
                    </td>
                    <td>
                        <input class='mr-5' type="datetime-local" :id="'add_out_new'" :min="convertTime(start)"
                            :max="convertTime(end)" />
                    </td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>
                        <button @click="addPunch('new')">Add Punch</button>
                    </td>
                </tr>
            </tbody>
        </table>


    </div>
</template>
<style>
#timeclock-admin {
    display: none;
}
</style>

<script>
export default {
    data() {
        return {
            weeksBack: 0,
            users: null,
            allUsers: null,
            start: "",
            end: "",
            dataFetched: false,
        }
    },

    mounted() {
        // console.log('Component mounted.');
        this.getPunches(() => { this.dataFetched = true; $("#timeclock-admin").show(); });
    },

    created() {
        // console.log(this.noteData);
        // console.log(this.noteableType);
    },
    computed: {
        sortedPunches: function () {
            return _.orderBy(this.users, [function(item){return item.user.name;}], ['asc'])
        },
        startDate: function () {
            return this.start.split(" ")[0];
        }
    },

    methods: {
        formatDate(date) {
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0' + minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear() + "  " + strTime;
        },
        getPunches(successCallBack = null) {
            axios.get('/admin/timeclock/load/' + this.weeksBack)
                .then(response => {
                    console.log(response);
                    this.users = response.data.users;
                    this.start = response.data.startDate;
                    this.end = response.data.endDate;
                    this.allUsers = response.data.allUsers;
                    if (typeof successCallBack === 'function') {
                        successCallBack();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        previous_week() {
            this.weeksBack++;
            this.getPunches();
        },
        next_week() {
            this.weeksBack--;
            this.getPunches();
        },
        convertTime(t) {
            if(t){
                return t.replace(" ", "T");
            }
            return "";
        },
        deletePunch(punch_id) {
            var confirmed = confirm("Are you sure you want to delete this punch?");
            if (confirmed) {
                axios.post('/admin/timeclock/delete_punch', {
                    punch_id: punch_id
                }).then(response => {
                    if (!response.data['success']) {
                        alert("Delete failed");
                    }
                    this.getPunches();
                })
                    .catch(error => {
                        console.log(error);
                        alert('Error: make sure you are logged into the system');
                    });
            }
        },
        addPunch(userId) {
            let inTime = $("#add_in_" + userId).val();
            let outTime = $("#add_out_" + userId).val();
            let originalUserId = userId;
            if (userId == 'new') {
                userId = $("#add_user_id").val();
            }
            axios.post('/admin/timeclock/add_punch', {
                user_id: userId,
                in_time: inTime.replace("T", " ") + ":00",
                out_time: outTime.replace("T", " ") + ":00",
            }).then(response => {
                console.log(response);
                if (!response.data['success']) {
                    alert("Add failed");
                } else {
                    $("#add_in_" + originalUserId).val("");
                    $("#add_out_" + originalUserId).val("");
                    // $("#add_user_id").val("")
                    // $('select option:first-child').attr("selected", "selected");
                }
                this.getPunches();
            }).catch(error => {
                console.log(error);
                alert('Error: make sure you are logged into the system');
            });
        },
        updateTime(e) {
            // console.log(e.target.getAttribute("punch_id") + " " + e.target.getAttribute("punch_type") + " " + e.target.value);
            let punchId = e.target.getAttribute("punch_id"); 
            let punchType = e.target.getAttribute("punch_type"); 
            let value = e.target.value;
            if(value==""){
                return;
            }
            axios.post('/admin/timeclock/update_punch', {
                punch_id: punchId,
                punch_type: punchType,
                value: value
            }).then(response => {
                console.log(response);
                if (!response.data['success']) {
                    alert("Update failed");
                }
                this.getPunches();
            })
                .catch(error => {
                    console.log(error);
                    alert('Error: make sure you are logged into the system');
                });
        }
    }
}
</script>
