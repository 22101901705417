<template>
    <div class="container-fluid">
      <h2>Shopify Order Fulfillment</h2>
      <form @submit.prevent="handleSubmit">
        <div v-if="step === 1" class="form-group">
          <label for="barcode">Shopify Package Barcode:</label>
          <input type="text" v-model="barcode" id="barcode" class="form-control" ref="barcodeInput">
          <button @click.prevent="getOrderDetails" class="btn btn-primary">Submit Barcode</button>
        </div>
        <div v-if="step === 2 && localOrderDetails" class="form-group">
          <p v-if="shopifyOrderId">Order ID: {{ shopifyOrderId }}</p>
          <p>Seq Num: {{ localOrderDetails.fulfillment.seq_num }}</p>
          <p>Status: {{ localOrderDetails.fulfillment.status }}</p>
          <p>Box Size: {{ localOrderDetails.packageData.box_size }}</p>
          <!-- <p>Weight (grams): {{ localOrderDetails.packageData.total_weight_oz * 28.3495 }}</p> -->
          <p>Items:</p>
          <ul>
            <li v-for="item in localOrderDetails.packageData.items" :key="item.variant_id">
              {{ item.title }} - {{ item.quantity }} pcs
            </li>
          </ul>
          <label for="weight">Weight (Grams):</label>
          <input type="text" v-model="weight" id="weight" class="form-control" ref="weightInput">
          <button @click.prevent="confirmWeight" class="btn btn-success">Confirm Weight</button>
        </div>
        <div v-if="step === 3" class="form-group">
          <label for="userpin">Enter PIN:</label>
          <input type="password" v-model="userpin" id="userpin" class="form-control" ref="pinInput">
          <button @click.prevent="confirmPin" class="btn btn-success">Confirm PIN</button>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      orderDetails: {
        type: Object,
        required: false
      }
    },
    data() {
      return {
        barcode: '',
        weight: '',
        userpin: '',
        step: 1, // Start with the barcode step
        localOrderDetails: this.orderDetails || null // Create a local copy of orderDetails
      };
    },
    computed: {
      shopifyOrderId() {
        return this.localOrderDetails?.fulfillment?.shopify_order_id || '';
      }
    },
    watch: {
      step(newStep) {
        this.$nextTick(() => {
          this.focusInput(newStep);
        });
      }
    },
    mounted() {
      console.log(this.localOrderDetails);
      this.$nextTick(async () => {
        if (this.localOrderDetails) {
          this.step = 2;
        }
        this.focusInput(this.step);        
      });
    },
    methods: {
      focusInput(step) {
        if (step === 1 && this.$refs.barcodeInput) {
          this.$refs.barcodeInput.focus();
        } else if (step === 2 && this.$refs.weightInput) {
          this.$refs.weightInput.focus();
        } else if (step === 3 && this.$refs.pinInput) {
          this.$refs.pinInput.focus();
        }
      },
      async getOrderDetails() {
        if (!this.barcode) {
          alert('Please enter the barcode.');
          return;
        }

        try {
          const response = await axios.post('/admin/shopify/fetch_order', { barcode: this.barcode });
          this.localOrderDetails = response.data; // Update the local copy
          
          if(this.localOrderDetails.error){
            alert(this.localOrderDetails.error);
            return;
          }
          this.step = 2;
        } catch (error) {
          alert('Error fetching order details');
        }
      },
      confirmWeight() {
        if (!this.weight) {
          alert('Please enter the weight in grams.');
          return;
        }

        // Convert expected weight from ounces to grams
        const expectedWeightGrams = this.localOrderDetails.packageData.total_weight_oz * 28.3495;
        const weightInGrams = parseFloat(this.weight);
        const diff = Math.abs(expectedWeightGrams - weightInGrams);

        if (diff > 30) {
          const userInput = prompt('Weight does not match the expected value. Type "confirmed" to proceed.');
          if (userInput.toLowerCase() !== 'confirmed') {
            return;
          }
        }

        this.step = 3; // Move to the PIN step
      },
      async confirmPin() {
        if (!this.userpin) {
          alert('Please enter your PIN.');
          return;
        }

        try {
          const response = await axios.post(`/admin/shopify/update_order/${this.localOrderDetails.fulfillment.id}`, {
            weight: this.weight,
            userpin: this.userpin
          });
          alert(response.data.message);
          this.resetForm();
        } catch (error) {
          alert(error.response.data.error || 'Error updating order status');
        }
      },
      resetForm() {
        this.barcode = '';
        this.weight = '';
        this.userpin = '';
        this.localOrderDetails = null; // Reset the local copy
        this.step = 1; // Reset to the barcode step
      }
    },
  };
  </script>
  
  <style scoped>
  /* Add any component-specific styles here */
  </style>