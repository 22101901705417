<template>
    <div class="container">
        <div id='notes_section'>
            <ul class="list-group list-group-flush notes">
                <li v-for="note in notes" v-bind:key="note.id" class="list-group-item">
                    <p class="mb-1 text-secondary"><small>{{note.user.name}}</small><small
                            class="float-right">{{formatDate(new Date(note.created_at))}}</small></p>
                    <button type="button" class="btn btn-sm pull-right removeNote"
                        @click="deleteNote(note.id)">X</button>
                    <p>{{note.note}}</p>
                </li>

                <li class="list-group-item">
                    <div class="form-group">
                        <label for="message-text" class="col-form-label">Add note:</label>
                        <textarea class="form-control" id="message-text" name="note" rows="3" maxlength="250"
                            v-model='newNote' ignore-v-on:keyup.enter="addNote"></textarea>
                    </div>
                    <button type="button" class="btn btn-primary saveNote" @click="addNote">Save</button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: ['noteableType', 'noteableId'],

    data() {
        return {
            notes: null,
            newNote: ''
        }
    },

    mounted() {
        axios.post('/admin/notes/load', {
            noteableType: this.noteableType,
            noteableId: this.noteableId,
        })
            .then(response => {
                this.notes = response.data;
            })
            .catch(error => {
                console.log(error);
            });
    },

    created() {
    },

    methods: {
        formatDate(date) {
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0' + minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear() + "  " + strTime;
        },
        addNote() {
            axios.post('/admin/notes/add', {
                message: this.newNote,
                noteableType: this.noteableType,
                noteableId: this.noteableId,
            })
                .then(response => {
                    this.notes = response.data;
                })
                .catch(error => {
                    console.log(error);
                });
            this.newNote = '';
        },
        deleteNote(id) {
            let result = confirm('Are you sure you want to delete this note?');
            if (result) {
                axios.post('/admin/notes/delete', {
                    id: id,
                    noteableType: this.noteableType,
                    noteableId: this.noteableId,
                })
                    .then(response => {
                        this.notes = response.data;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    }
}
</script>
